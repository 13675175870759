import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 256 145.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "m97.2 32.8-33.5 80H41.8L25.3 48.9c-1-3.9-1.9-5.4-4.9-7-5-2.7-13.2-5.2-20.4-6.8l.5-2.3h35.2c4.5 0 8.5 3 9.5 8.1l8.7 46.3 21.6-54.4zm29.6 0-17.1 80H89l17.1-80zm56.1 53.8c.1-21.1-29.2-22.3-29-31.7.1-2.9 2.8-5.9 8.8-6.7 3-.4 11.1-.7 20.4 3.6l3.6-17c-5-1.8-11.4-3.5-19.3-3.5-20.5 0-34.8 10.9-35 26.4-.1 11.5 10.3 17.9 18.1 21.8 8.1 3.9 10.8 6.4 10.7 9.9-.1 5.4-6.4 7.7-12.4 7.8-10.4.2-16.4-2.8-21.2-5.1l-3.8 17.5c4.8 2.2 13.8 4.1 23 4.2 21.8.2 36-10.6 36.1-27.2m54 26.1H256l-16.7-80h-17.6c-4 0-7.3 2.3-8.8 5.9l-31 74.1h21.7l4.3-11.9h26.5zm-23.1-28.3 10.9-30 6.3 30z",
      class: "fill-[#1A1F71]"
    }, null, -1)
  ])))
}
export default { render: render }